import React from "react";
import CaroselPage from "../components/CarouselPage";
import me from "../assets/svg/medical-equipment.svg";
// import de from "../assets/svg/dental-care.svg";
import "../components/Aboutus.css";

import diamond from "../assets/svg/shapes/diamondpink.svg";

import en from "../assets/svg/engineering.svg";
import ay from "../assets/svg/home-remedies.svg";
import "./HomePage.css";
import Sitemap from "../components/Sitemap";

export default function HomePage() {
  return (
    <div>
      <CaroselPage></CaroselPage>
      <HomeSectionTwo></HomeSectionTwo>
      <Sitemap></Sitemap>
    </div>
  );
}

export function HomeSectionTwo() {
  const ServiceList1 = [
    {
      icon: me,
      title: "Medical",
      text: "Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text",
    },
    {
      icon: en,
      title: "Engineering",
      text: "Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text",
    },
    {
      icon: ay,
      title: "Others",
      text: "Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy text",
    },
  ];

  return (
    <div className="Home-section-Two" id="HomeId">
      <h1 className="Home-section-title">Our Services</h1>
      <div className="Home-slogan-container">
        <div>
          <div className="Home-slogan">
            <img src={diamond} alt="" className="diamond_bullet_point"></img>
            <p>GUIDANCE</p>
          </div>
          <div className="Home-slogan">
            <img src={diamond} alt="" className="diamond_bullet_point"></img>
            <p>ADMISSIONS</p>
          </div>
        </div>
        <div>
          <div className="Home-slogan">
            <img src={diamond} alt="" className="diamond_bullet_point"></img>
            <p>CAREERS</p>
          </div>
          <div className="Home-slogan">
            <img src={diamond} alt="" className="diamond_bullet_point"></img>
            <p>CHARITY</p>
          </div>
        </div>
      </div>

      <div className="Home-section-wrapper">
        <div className="Service-wrapper-one">
          {ServiceList1.map((item, index) => {
            return <ServiceCard key={index} content={item} />;
          })}
        </div>
      </div>
      <div className="contact-strip">
        <p>Do you want to join the best colleges?</p>
        <button className="Home-page-click-btn">
          <a href="https://forms.gle/kHiRAfonxrqVCD7VA"> Click here</a>
        </button>
      </div>
    </div>
  );
}

export function ServiceCard(props) {
  return (
    <div className="Service-card-container">
      <div className="Service-card-hexagon">
        <div className="Service-card-shape">
          <img className="Service-icon" src={props.content.icon} alt=""></img>
          <p className="Service-title">{props.content.title}</p>
          <a
            className="Service-btn"
            href={`counselling/${props.content.title}`}
          >
            {" "}
            Click
          </a>
        </div>
      </div>
    </div>
  );
}
